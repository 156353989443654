import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { influKeywordHistory } from '../models/influencer-keyword-history.model';
import { InfluKeywordRequest } from '../models/influencer-keyword-req.model';
import { PageResponse } from '../models/page-response.model';

@Injectable({
  providedIn: 'root'
})
export class InfluencerKeywordService {
  private readonly api_url = `${environment.api_url}/api`

  constructor(private readonly http: HttpClient) {}

  exportInfluencerKeywordReport(influencerKeywordHistoryId:String): Observable<Blob> {
    const params = new HttpParams()
      .set('influencerKeywordHistoryId', influencerKeywordHistoryId.toString())
    return this.http.get(`${this.api_url}/external/influ-keyword-report`, {
      headers: { 'Content-Type': 'application/json' },
      responseType: 'blob',
      params,
    })
  }

  getInfluencerList(body:InfluKeywordRequest){
    return this.http.post<any>(`${this.api_url}/influencer-keyword`,body)
  }

  getSearchHistory(page:number, size:number){
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString())
    return this.http.get<PageResponse<influKeywordHistory>>(`${this.api_url}/influencer-keyword/history`,{params})
  }
}
